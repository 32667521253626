<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title v-if="detail.id" :value="`LOT ${detail.number || ''} ${detail.title || ''}`"/>
    <auction-item-toggle-control
      class="hidden-xs-only"
      sticky
      :routeTo="{ name: 'auctionCategoryDetail', params: { id: detail.auction_category_id } }"
      :title="$t('toggleTitle')"
      :show-next="!!nextItem"
      :show-pre="!!preItem"
      @pre="handleNavigate('pre')"
      @next="handleNavigate('next')"
    >
      <template slot="pre">{{ $t('prev') }}</template>
      <template slot="next">{{ $t('next') }}</template>
    </auction-item-toggle-control>
    <div class="container">
      <div class="page-wrapper">
        <div class="auction-item-title hidden-sm-and-up">
          <span class="label" v-if="detail.auction_way === 'offline'">{{$t('offline')}}</span>
          <span>LOT {{ detail.number }} {{ detail.title }}</span>
        </div>
        <el-row :gutter="30" v-if="!loading">
          <el-col :xs="24" :sm="12">
            <auction-item-swiper :images="detail.images"/>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div class="auction-item-title hidden-xs-only">
              <span class="label" v-if="detail.auction_way === 'offline'">{{$t('offline')}}</span>
              <span>LOT {{ detail.number }} {{ detail.title }}</span>
            </div>
            <div class="price-wrapper">
              <div v-if="detail.is_finished && detail.show_items_final_price && authStore.isLogin">
                <div v-if="['hammered', 'force_hammered'].includes(detail.status)">
                  <div>
                    <span>{{$t('dealPrice')}}: </span>
                    <span class="text-uppercase">{{ currency }}</span>
                    <span class="primary">{{ detail.final_price | currencyFormat }}</span>
                  </div>
                  <div v-if="!$options.filters.isLocaleCurrency(currency)">
                    <span>{{$t('dealPrice')}}: </span>
                    <span>{{$t('currency')}} </span>
                    <span class="primary">{{ detail.final_price | conversionFormat(currency) | currencyFormat }}</span>
                  </div>
                </div>
                <div v-else>
                  {{$t('aborted')}}
                </div>
              </div>
              <div v-else>
                <div v-if="detail.starting_price <= 0">
                  {{ $t('estimatePending') }}
                </div>
                <template v-else-if="detail.estimate_price_from && detail.estimate_price_to">
                  <div>
                    <span>{{$t('estimatedPrice')}}: </span>
                    <span class="text-uppercase">{{ currency }} </span>
                    <span class="primary">{{ detail.estimate_price_from | currencyFormat }}-{{ detail.estimate_price_to | currencyFormat }}</span>
                  </div>
                  <div v-if="!$options.filters.isLocaleCurrency(currency)">
                    <span>{{$t('estimatedPrice')}}: </span>
                    <span>{{$t('currency')}} </span>
                    <span class="primary">{{ detail.estimate_price_from | conversionFormat(currency) | currencyFormat }}-{{ detail.estimate_price_to | conversionFormat(currency) | currencyFormat }}</span>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <span>{{$t('startPrice')}}: </span>
                    <span class="text-uppercase">{{ currency }} </span>
                    <span class="primary">{{ detail.starting_price | currencyFormat }}</span>
                  </div>
                  <div v-if="!$options.filters.isLocaleCurrency(currency)">
                    <span>{{$t('startPrice')}}: </span>
                    <span>{{$t('currency')}} </span>
                    <span class="primary">{{ detail.starting_price | conversionFormat(currency) | currencyFormat }}</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="detail-wrapper" v-if="detail.desc">
              <div class="detail-title">{{$t('detailTitle')}}</div>
              <div class="detail-info" v-html="$options.filters.htmlLineBreak(detail.desc)"/>
            </div>
          </el-col>
        </el-row>

        <participate-button
          v-show="!$get(detail, 'auction.is_finished') & !number"
          :number="number"
          :quotas="quotas"
          :auction="{ ...detail.auction, quota_currency: $get(detail, 'auction_category.quota_currency') }"
          @update-number="number = $event"
          pageType="auctionItem"
          class="btns-wrap"
        />

        <app-auction-item-status
          class="auction-status"
          :auction="detail.auction"
          :server-time="serverTime"
          @finished="handleFinished"
        />

        <!-- 事前出价 -->
        <div v-if="showPreBidPrice">
          <div class="box-limit">
            <div class="flex item-center content-between">
              <div class="flex item-center">
                <div class="label">{{ $t('currentLimit') }}：</div>
                <div class="flex item-center">
                  <div class="currency text-uppercase">{{ currency }}</div>
                  <div class="amount">{{ availableAmount | conversionFormat($get(detail, 'auction_category.quota_currency'), currency ) | currencyFormat }}</div>
                </div>
              </div>
              <router-link
                class="btn-increase"
                :to="{ name: 'charge', query: { auctionId: detail.auction.id, selectCurrency: $get(detail, 'auction_category.quota_currency') } }"
              >
                {{ $t('increaseAmount') }}
              </router-link>
            </div>
            <div class="margin-ratio" v-if="detail.auction.margin_ratio !== 1">
              <div v-if="$i18n.locale !== 'en'">{{ $t('marginTip') }}<span>{{ detail.auction.margin_ratio }}</span>{{ $t('multiple') }}</div>
              <div v-else>It is <span>{{ detail.auction.margin_ratio }}</span> times the top-up amount</div>
            </div>
          </div>
          <div class="operation">
            <div class="bid-manual">
              <div v-if="hadBidPrice || !showChangePrice" class=" flex item-center content-between">
                <div class="flex item-center">
                  <div class="label">{{ $t('preBid') }}：</div>
                  <div class="flex item-center">
                    <div class="currency text-uppercase">{{ currency }}</div>
                    <div class="amount">{{ preBidPrice | currencyFormat }}</div>
                  </div>
                </div>
                <div class="flex">
                  <div :class="['btn-bid small', { disabled: itemIsStart || hadBidPrice }]" @click="handleChangePrice">{{ $t('changeBtn') }}</div>
                  <div :class="['btn-bid small', { disabled: !showCancel || itemIsStart || isEndBiddingTime }]" style="margin-left: 0.66667rem" @click="handleCancelPrice">{{ $t('cancel') }}</div>
                </div>
              </div>
              <div v-if="!isEndBiddingTime && !itemIsStart">
                <div v-if="showChangePrice" class=" flex item-center content-between">
                  <div class="number-box flex item-center">
                    <div :class="['btn', {disabled: disabledMins}]" @click="!disabledMins && handleChange('minusPrice')">
                      <span class="el-icon-minus"/>
                    </div>
                    <div class="price-wrapper flex item-center content-center">
                      <span class="price-text">{{ preBidPrice | currencyFormat }}</span>
                    </div>
                    <div :class="['btn', {disabled: disabledAdd}]" @click="!disabledAdd && handleChange('addPrice')">
                      <span class="el-icon-plus"/>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="btn-bid small" @click="handlePrePrice">{{ $t('preBid') }}</div>
                    <div :class="['btn-bid small', { disabled: !showCancel || itemIsStart || isEndBiddingTime }]" style="margin-left: 0.66667rem" @click="handleCancelPrice">{{ $t('cancel') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <common-title :title="$t('recommmendTitle')" v-if="list.length"/>
        <div style="margin: 1.66667rem 0;">
          <el-row :gutter="15">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              v-for="item in list"
              :key="item.id"
            >
              <router-link
                class="item"
                :to="{ params: { id: item.id } }"
              >
                <auction-item-card
                  :cover="$get(item, 'cover.url')"
                  :info="(item.desc || '').replace(/<[^>]+>/ig, '')"
                >
                  <div slot="title" v-if="item.number" style="margin-bottom: 0.25rem">LOT {{ item.number }}</div>
                  <div class="price">
                    <div>{{$t('startPrice')}}: <span class="text-uppercase">{{ item.auction_category.currency }}</span> {{ item.starting_price | currencyFormat }}</div>
                    <div v-if="!$options.filters.isLocaleCurrency(item.auction_category.currency)">{{$t('startPrice')}}: {{$t('currency')}} {{ item.starting_price | conversionFormat(item.auction_category.currency) | currencyFormat }}</div>
                  </div>
                </auction-item-card>
              </router-link>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <app-fixed-bottom class="hidden-sm-and-up">
      <auction-item-toggle-control
        :routeTo="{ name: 'auctionCategoryDetail', params: { id: detail.auction_category_id } }"
        :title="$t('toggleTitle')"
        :show-next="!!nextItem"
        :show-pre="!!preItem"
        @pre="handleNavigate('pre')"
        @next="handleNavigate('next')"
      >
        <template slot="pre">{{ $t('prev') }}</template>
        <template slot="next">{{ $t('next') }}</template>
      </auction-item-toggle-control>
    </app-fixed-bottom>
    <follow-share :detail="detail" targetType="AuctionItem" />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore, productIndexStore } from '@/store';
  import _ from 'lodash';
  import dayjs from 'dayjs';
  import ParticipateButton from '@/components/auction/participate-button.vue';

  @Component({ components: { ParticipateButton } })
  export default class AuctionItemsShow extends Vue {
    serverTime = Date.now();
    authStore = authStore;
    loading = true;
    preBidPrice = 0;
    highestPrice = 0;
    showChangePrice = true;
    showCancel = false;
    number = '';
    quotas = [];

    authStore = authStore;
    productIndexStore = productIndexStore;

    detail = {};
    list = [];

    get currency() {
      return this.$get(this.detail, 'auction_category.currency');
    }

    // 拍品已经开始拍卖了
    get itemIsStart() {
      return this.$get(this.detail, 'start_at');
    }

    get availableAmount() {
      const quotaCurrency = this.$get(this.detail, 'auction_category.quota_currency');
      const currentQuota = this.quotas.find(item => item.currency === quotaCurrency);

      return this.$get(currentQuota, `available_bidding_amount.${this.detail.auction.margin_ratio}`);
    }

    get preItem() {
      const { productType } = this.$route.query;
      if (productType) {
        return this.productIndexStore.preItem;
      }
      const id = this.detail.prev_auction_item_id;
      if (id) {
        return {
          id,
          target_type: 'AuctionItem'
        };
      }
      return null;
    }

    get nextItem() {
      const { productType } = this.$route.query;
      if (productType && productIndexStore.isFulfilled) {
        return this.productIndexStore.nextItem;
      }
      const id = this.detail.item_detail_next_auction_item_id;
      if (id) {
        return {
          id,
          target_type: 'AuctionItem'
        };
      }
      return null;
    }

    // 小于起拍价或小于当前事前出价禁止点击
    get disabledMins() {
      return this.preBidPrice <= this.detail.starting_price || this.preBidPrice <= this.highestPrice;
    }

    // 出价超过限制额度禁止点击
    get disabledAdd() {
      const filter = this.$options.filters;
      return this.addPrice(this.preBidPrice) > filter.conversionFormat(this.availableAmount, this.$get(this.detail, 'auction_category.quota_currency'), this.currency);
    }

    get isFinished() {
      return dayjs(this.$get(this.detail, 'auction_start_at')).diff(dayjs()) < 0 ;
    }

    // 事前出价结束时间
    get isEndBiddingTime () {
      return dayjs(this.$get(this.detail, 'ended_bidding_time')).diff(dayjs()) < 0;
    }

    // 没有事前出价结束时间
    get noBiddingTime () {
      return this.$get(this.detail, 'ended_bidding_time') === null;
    }

    // 是否显示事前出价   线上 + 拍卖会已经开始或者未结束 + 有牌号 + 有事前出价结束时间 + 该拍品还没开始
    get showPreBidPrice () {
      return this.isOnline && (this.isStarted || !this.isFinished) && this.number && !this.noBiddingTime && !this.itemIsStart;
    }

    get hadBidPrice() {
      return this.isEndBiddingTime && this.$get(this.detail, 'auto_bidding_price');
    }

    get isOnline() {
      return this.$get(this.detail, 'auction_way') !== 'offline';
    }

    get isStarted() {
      const { start_at, is_finished } = this.$get(this.detail, 'auction', {});
      return !is_finished && dayjs(this.serverTime).diff(dayjs(start_at)) >= 0;
    }

    get raiseRules() {
      return _.get(this.detail, 'auction_category.current_raise_rule_set.raise_rules') || [];
    }

    @Vue.autoLoading
    async created() {
      // 用promise.all有可能会出错
      await this.auctionsAction('post', 'visit'),
      await this.fetchData();
      productIndexStore.setRouteParams(this.$route.params, this.$route.query);
      await productIndexStore.tryFetchData();
    }

    async fetchData() {
      const { params: { id }, query: { keyword, sort, auction_category_id_eq } } = this.$route;
      const [
        { data, headers },
        { data: list },
      ] = await Promise.all([
        this.$fly.get(`auction_items/${id}`, { keyword, sort, auction_category_id_eq }),
        this.$fly.get(`auction_items/${id}/recommended_items`),
      ]);
      this.loading = false;
      this.detail = data;
      this.list = list;
      this.serverTime = headers.date;

      if (authStore.isLogin) {
        const [{ data: price }, { data: { number, quotas } }] = await Promise.all([
          this.$fly.get('auto_biddings', {
            targetable_type_cont: 'AuctionItem',
            targetable_id_eq: id
          }),
          this.$fly.get('/mine/auction_number', { auction_id: this.detail.auction.id })
        ]);

        this.number = number;
        this.quotas = quotas;
        price.length && (this.preBidPrice = price[0].highest_price);

        if (this.preBidPrice !== 0) {
          this.highestPrice = this.preBidPrice;
          this.showChangePrice = false;
          this.showCancel = true;
        } else {
          this.preBidPrice = this.detail.starting_price;
          this.showCancel = false;
        }
      }
    }

    // 提交 事前出价
    async handlePrePrice() {
      const filter = this.$options.filters;
      // 首次进入页面，事前出价为起拍价，当起拍价大于当前额度时
      if (this.detail.starting_price > filter.conversionFormat(this.availableAmount, this.$get(this.detail, 'auction_category.quota_currency'), this.currency)) {
        await this.$message({ type: 'error', message: this.$t('prePriceError') });
        return;
      }
      const { id } = this.$route.params;
      if (this.preBidPrice !== this.highestPrice) {
        await this.$confirm(`${this.$t('confirmPreBidPrice')}
            ${this.currency.toUpperCase()}
            ${this.$options.filters.currencyFormat(this.preBidPrice)} ？`);
        await this.$autoLoading(this.$fly.post('/auto_biddings', {
          targetable_type: 'AuctionItem',
          targetable_id: id,
          highest_price: this.preBidPrice
        }));
        this.highestPrice = this.preBidPrice;
        await this.$message({
          type: 'success',
          message: `${this.$t('preBidPriceSuccess')}
            ${this.currency.toUpperCase()}
            ${this.$options.filters.currencyFormat(this.highestPrice)}`
        });
      }
      this.showCancel = true;
      this.showChangePrice = false;
    }

    handleChangePrice() {
      this.showChangePrice = true;
    }

    handleNavigate(type) {
      const item = type === 'next' ? this.nextItem : this.preItem;
      if (item) {
        const id = _.get(item, 'id') || _.get(item, 'target_id');
        const name = {
          Product: 'productDetail',
          AuctionItem: 'auctionItemDetail'
        }[item.target_type];
        this.$router.replace({ name, params: { id }, query: { ...this.$route.query } });
      }
    }

    // 封装aucitons_store的请求
    auctionsAction(methods, action_type) {
      const { id } = this.$route.params;
      return this.$fly[methods]('action_stores', {
        action_type: action_type,
        target_type: 'AuctionItem',
        target_id: id
      });
    }

    async handleFinished() {
      await this.fetchData();
    }

    // 加价和减价的操作
    handleChange(type) {
      if (type === 'addPrice') {
        this.preBidPrice = this.preBidPrice < this.detail.starting_price ? this.detail.starting_price : this.addPrice(this.preBidPrice);
      } else {
        this.preBidPrice = this.minusPrice(this.preBidPrice) < this.detail.starting_price ? this.detail.starting_price : this.minusPrice(this.preBidPrice);
      }
    }

    // 取消事前出价的操作
    async handleCancelPrice() {
      const { id } = this.$route.params;

      await this.$confirm(`${this.$t('confirmCancelPreBidPrice')}
          ${this.currency.toUpperCase()}
          ${this.$options.filters.currencyFormat(this.preBidPrice)} ？`);
      await this.$autoLoading(this.$fly.delete('/auto_biddings', {
        targetable_type: 'AuctionItem',
        targetable_id: id
      }));
      await this.$message({
        type: 'success',
        message: `${this.$t('cancelPreBidPriceSuccess')}
          ${this.currency.toUpperCase()}
          ${this.$options.filters.currencyFormat(this.highestPrice)}`
      });

      this.showChangePrice = true;
      this.preBidPrice = this.detail.starting_price;
      this.highestPrice = 0;
      this.showCancel = false;
    }

    /**
     * @param {Number} price - 当前价
     * @param {Number} basePrice - 基数
     * @param {Array} range - 价格临界点 [100, 200]
     * @param {Boolean} reverse - 减法
     */
    calcStepPrice(price, basePrice, range, reverse) {
      const [min, max] = range;
      const steps = [0, 2, 5, 8].map(item => item * (basePrice / 10));
      const remainder = price % basePrice;
      const stepPrice = reverse ? steps.reverse().find(step => step < remainder) : steps.find(step => step > remainder);
      const intPirce = Math.floor(price / basePrice) * basePrice;
      if (!_.isUndefined(stepPrice)) {
        return Math.min(intPirce + stepPrice, max);
      }
      // 跳到下一个阶段
      if (reverse) {
        return Math.max(intPirce - basePrice + steps[0], min);
      }
      return Math.min(intPirce + basePrice + steps[0], max);
    }

    addPrice(price) {
      const rules = _.cloneDeep(this.raiseRules).sort((a, b) => b.start_at - a.start_at);
      let rule = rules.find(item => price >= item.start_at && price < item.end_at);
      // 没有匹配的规则取第一个规则
      if (!rule && rules.length) {
        rule = {
          ...rules[0],
          end_at: Number.MAX_SAFE_INTEGER
        };
      }
      if (!rule) {
        return price + 500;
      }
      if (rule.kind === 'two_five_eight') {
        return this.calcStepPrice(price, Math.pow(10, String(price).length - 1), [rule.start_at, rule.end_at]);
      }
      if (rule.kind === 'normal') {
        return Math.min(Math.floor(price / rule.minimum) * rule.minimum + rule.minimum, rule.end_at);
      }
    }

    minusPrice(price) {
      const rules = _.cloneDeep(this.raiseRules).sort((a, b) => a.start_at - b.start_at);
      let rule = rules.find(item => price > item.start_at && price <= item.end_at);
      // 没有匹配的规则取第一个规则
      if (!rule && rules.length) {
        rule = {
          ...rules[0],
          start_at: 0
        };
      }
      if (!rule) {
        return Math.max(0, price - 500);
      }
      if (rule.kind === 'two_five_eight') {
        return this.calcStepPrice(price, Math.pow(10, String(price).length - 1), [rule.start_at, rule.end_at], true);
      }
      if (rule.kind === 'normal') {
        return Math.max(Math.floor(price / rule.minimum) * rule.minimum - rule.minimum, rule.start_at);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-wrapper {
    @include media-sm-and-up {
      padding-top: 50px;
    }
  }

  .btns-wrap {
    padding: 25px 0;
  }

  .auction-status {
    margin-bottom: 25px;
  }

  .box-limit {
    width: 100%;
    padding-bottom: 23px;
    margin: 22px 0 6px;
    border-bottom: 1px solid #999;

    .margin-ratio {
      font-weight: 500;
      font-size: 12px;
      color: $gray;

      span {
        margin: 0 2px;
        font-size: 16px;
        color: $primary;
      }
    }
  }

  .label {
    margin-top: 2px;
    font-weight: 500;
    font-size: 12px;
    color: $gray;
  }

  .currency {
    font-weight: 500;
    font-size: 18px;
    color: #000;
  }

  .amount {
    margin-left: 5px;
    font-weight: 500;
    font-size: 21px;
    color: $primary;
  }

  .btn-increase {
    padding: 6px 14px;
    border-radius: 15px;
    font-size: 13px;
    color: #fff;
    background: $primary;
    cursor: pointer;
  }

  .operation {
    padding-bottom: 20px;
    margin: 26px 0;

    .btn-bid {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 30px;
      padding: 0 15px;
      border-radius: 15px;
      font-size: 13px;
      color: #fff;
      background: $primary;
      cursor: pointer;

      &.small {
        padding: 0 8px;
        font-size: 10px;
      }

      &.disabled {
        color: #fff;
        background: #ccc;
        pointer-events: none;
      }
    }

    .number-box {
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 13px;
        color: #fff;
        background: #000;
        cursor: pointer;

        &.disabled {
          background: #ccc;
          cursor: none;
          pointer-events: none;
        }

        .el-icon-minus, .el-icon-plus {
          font-weight: bold;
        }
      }

      .price-wrapper {
        width: 104px;
        height: 40px;
        margin: 0 16px;
        border: 1px solid #ddd;
        background: #f0f0f0;
      }
    }
  }

  .auction-item-title {
    padding: 20px 0;
    font-weight: 800;
    font-size: 16px;
    color: #000;

    &.hidden-xs-only {
      padding-top: 0;
    }

    .label {
      padding: 2px 7px;
      margin-right: 5px;
      border-radius: 2px;
      font-size: 9px;
      vertical-align: middle;
      color: #fff;
      background: $primary;
    }
  }

  .price-wrapper {
    padding: 20px 0;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    background: #eee;

    .primary {
      color: $primary;
    }
  }

  .detail-wrapper {
    margin-bottom: 24px;

    .detail-title {
      margin-bottom: 20px;
      font-weight: 800;
      font-size: 14px;
      color: #000;
    }

    .detail-info {
      font-size: 12px;
      line-height: 1.8;
      color: #666;
    }
  }

  .fixed-wrapper {
    position: fixed;
    z-index: var(--fixed-z-index);
    bottom: 50px;
    right: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35px;
    padding: 10px 0;
    border-radius: 18px;
    background: rgba(#000, 0.6);

    .icon {
      width: 18px;
      height: 18px;
      margin: 10px 0;
      cursor: pointer;
    }
  }
</style>
